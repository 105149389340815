import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { ContactFormSchema } from '../utils/validation';
import Column from './Column';
import Grid from './Grid';
import { MAIL_URL } from '../utils/constants';
import { scroller, Element } from 'react-scroll';

const FormLabel = ({ className, label, required = false, ...rest }) => (
	<label
		className={classNames(
			'block text-grey-darker text-sm font-bold mb-2',
			className,
		)}
		{...rest}
	>
		{label}
		{required && ' *'}
	</label>
);

const Label = ({ className, children, label }) => (
	<label className={classNames('cursor-pointer', className)}>
		{children}
		{label}
	</label>
);

const FormField = ({
	name,
	required = false,
	type = 'text',
	label,
	touched,
	error,
	...rest
}) => (
	<>
		<FormLabel
			label={label}
			required={required}
			htmlFor={name}
			className={classNames({
				'text-red': !!error && touched,
			})}
		/>
		<Field
			className={classNames(
				'shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline',
				{
					'text-red': !!error && touched,
				},
			)}
			id={name}
			name={name}
			type={type}
			placeholder={label}
			required={required}
			{...rest}
		/>
		<ErrorMessage
			name={name}
			component="div"
			className="text-red mt-2 text-sm"
		/>
	</>
);

const ContactForm = ({ className }) => {
	return (
		<Formik
			validateOnChange={true}
			validateOnBlur={true}
			initialValues={{
				firstname: '',
				lastname: '',
				street: '',
				city: '',
				phone: '',
				mail: '',
				message: '',
			}}
			validationSchema={ContactFormSchema}
			onSubmit={(values, actions) => {
				actions.setSubmitting(true);

				fetch(MAIL_URL, {
					method: 'POST',
					body: JSON.stringify(values),
					headers: {
						'Content-Type': 'application/json',
					},
				})
					.then((res) => res.json())
					.then((res) => {
						actions.setSubmitting(false);

						if (res.status) {
							if (res.status === 'ok') {
								actions.resetForm();
								actions.setStatus({
									msg: 'Vielen Dank für Ihre Kontaktanfrage.',
									type: 'success',
								});
								scroller.scrollTo('status-message');
							} else {
								actions.setErrors(res.errors);
								scroller.scrollTo('form-container');
							}
						} else {
							actions.setStatus({
								msg: 'Es ist ein Fehler aufgetreten.',
								type: 'error',
							});
							scroller.scrollTo('status-message');
						}
					})
					.catch((err) => {
						actions.setSubmitting(false);
						actions.setStatus({
							msg: 'Es ist ein Fehler aufgetreten.',
							type: 'error',
						});
						scroller.scrollTo('status-message');
					});
			}}
			render={({ errors, status, touched, isSubmitting }) => {
				return (
					<Form>
						<Element name="form-container">
							<div
								className={classNames(
									'my-6 py-6 border-t border-b border-grey relative',
									className,
								)}
							>
								{status && status.msg && (
									<Element name="status-message">
										<div
											className={classNames(
												'flex border-t-4  rounded-b text-teal-darkest px-4 py-3 shadow-md mb-8 items-center',
												{
													'text-teal-darkest border-teal bg-teal-lightest':
														status.type !== 'error',
													'text-red-darkest border-red bg-red-lightest':
														status.type === 'error',
												},
											)}
											role="alert"
										>
											<FontAwesomeIcon
												size="2x"
												icon={faInfoCircle}
												className="mr-3"
											/>
											<p className="text-sm mb-0">
												{status.msg}
											</p>
										</div>
									</Element>
								)}

								<div className="mb-6">
									<FormLabel htmlFor="title" label="Anrede" />
									<Label className="mr-8" label="Herr">
										<Field
											type="radio"
											name="title"
											className="mr-2"
											value="Herr"
										/>
									</Label>

									<Label label="Frau">
										<Field
											type="radio"
											name="title"
											className="mr-2"
											value="Frau"
										/>
									</Label>
								</div>

								<Grid className="mb-6" noNegativMargin>
									<Column md="1/2">
										<FormField
											error={errors.firstname}
											touched={touched.firstname}
											name="firstname"
											label="Vorname"
											required
										/>
									</Column>
									<Column md="1/2">
										<FormField
											error={errors.lastname}
											touched={touched.lastname}
											name="lastname"
											label="Nachname"
											required
										/>
									</Column>
								</Grid>
								<Grid className="mb-6" noNegativMargin>
									<Column md="1/2">
										<FormField
											error={errors.street}
											touched={touched.street}
											name="street"
											label="Straße/Nr."
										/>
									</Column>
									<Column md="1/2">
										<FormField
											name="city"
											label="PLZ/Ort"
											error={errors.city}
											touched={touched.city}
										/>
									</Column>
								</Grid>
								<Grid className="mb-6" noNegativMargin>
									<Column md="1/2">
										<FormField
											error={errors.phone}
											touched={touched.phone}
											name="phone"
											label="Telefon"
											required
										/>
									</Column>
									<Column md="1/2">
										<FormField
											error={errors.mail}
											touched={touched.mail}
											name="mail"
											label="E-Mail"
											type="email"
										/>
									</Column>
								</Grid>
								<FormField
									error={errors.message}
									touched={touched.message}
									name="message"
									label="Nachricht"
									component="textarea"
									required
									rows="5"
								/>

								<label
									className={classNames(
										'cursor-pointer mt-6 block',
										{
											'text-red': errors.agb,
										},
									)}
								>
									<Field
										type="checkbox"
										name="agb"
										value="1"
										className="mr-1"
									/>
									Ich habe die{' '}
									<a
										target="_blank"
										className="text-blue hover:text-blue-dark"
										href="/kontakt/datenschutz"
									>
										Datenschutzbestimmungen
									</a>{' '}
									gelesen und akzeptiere diese. Ich bin damit
									einverstanden, dass meine Daten zur
									Kontaktaufnahme nur so lange wie notwendig
									gespeichert werden. *
								</label>
								{errors.agb && (
									<div className="text-red mt-2 text-sm">
										{errors.agb}
									</div>
								)}
								<div className="mt-4 text-right text-sm text-grey-dark my-3">
									Alle mit * gekennzeichneten Felder sind
									Pflichtfelder!
								</div>
								<div className="text-right mt-6">
									<input
										className={classNames(
											'shadow bg-blue hover:bg-blue-dark focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded cursor-pointer',
											{
												'opacity-50': isSubmitting,
											},
										)}
										type="submit"
										value={
											isSubmitting
												? 'Sendet Nachricht...'
												: 'Kontaktanfrage abschicken'
										}
										disabled={isSubmitting}
									/>
								</div>
							</div>
						</Element>
					</Form>
				);
			}}
		/>
	);
};

export default ContactForm;
