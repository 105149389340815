import * as Yup from 'yup';

const agbMessage = 'Die Datenschutzbestimmungen müssen akzeptiert werden.';

export const ContactFormSchema = Yup.object().shape({
    title: Yup.mixed().oneOf(['Herr', 'Frau']),
    firstname: Yup.string().required('Es muss ein Vorname angegeben werden.'),
    lastname: Yup.string().required('Es muss ein Nachname angegeben werden.'),
    street: Yup.string(),
    city: Yup.string(),
    phone: Yup.string().required(
        'Es muss eine Telefonnummer angegeben werden.'
    ),
    mail: Yup.string().email('Das ist keine gültige E-Mail Adresse.'),
    message: Yup.string().required('Es muss eine Nachricht angegeben werden.'),
    agb: Yup.boolean()
        .required(agbMessage)
        .oneOf([true], agbMessage),
});
