import React, { useEffect, useState } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContactForm from '../components/ContactForm';
import Layout from '../components/layout';
import { getVacationData } from '../utils/constants';

const Kontakt = ({ data, location, ...props }) => {
	const [vacationData, setVacationData] = useState(null);
	const [isContactOpen, setIsContactOpen] = useState(null);

	useEffect(() => {
		// Check vacation
		const vacationData = getVacationData();

		if (vacationData) {
			setVacationData(vacationData.vacationData);

			if (vacationData.contactOpen !== null) {
				setIsContactOpen(vacationData.contactOpen);
			}
		}
	}, []);

	return (
		<Layout location={location} title="Kontakt" noSlider {...props}>
			{!vacationData ||
			(vacationData && vacationData.isOpen) ||
			isContactOpen ? (
				<>
					<p>
						Sie möchten einen Termin vereinbaren oder haben konkrete
						Fragen? Nutzen Sie einfach unser Kontaktformular - Wir
						rufen Sie gerne zurück!
					</p>
					<div
						className="flex border border-grey-darker bg-grey-light text-grey-darkest text-sm font-bold px-4 py-3 mt-6 rounded my-4"
						role="alert"
					>
						<FontAwesomeIcon
							size="2x"
							icon={faInfoCircle}
							className="mr-3"
						/>
						<p className="mb-0">
							Achtung: Wir beantworten Ihre Anfragen
							ausschließlich telefonisch. Bitte geben Sie daher
							unbedingt eine Telefonnummer an, unter der wir Sie
							gut erreichen können!
						</p>
					</div>

					<ContactForm />

					<p>
						<strong>
							Praxis der Dermatologie & Aesthetic <br /> Dr. med.
							Krokowski
						</strong>
						<br />
						<br />
						Kölnische Straße 5<br />
						34117 Kassel
						<br />
						www.krokowski-dermatologie.de <br />
						<br />
						Telefon (05 61) 7 03 69 55
						<br />
						Telefax (05 61) 7 03 46 24
						<br />
						info@krokowski-dermatologie.de
					</p>
				</>
			) : (
				<>
					{vacationData.text}
					<p>
						<strong>Dr. med. Michael Krokowski</strong>
						<br />
						<strong>mit dem gesamten Praxisteam.</strong>
					</p>
				</>
			)}
		</Layout>
	);
};

export default Kontakt;
